<template>
  <div class="shipments-page" id="merchant-returns">
    <div class="flex items-center justify-between mb-4">
      <h2>{{$t('Returns')}}</h2>
      <shipment :user="user" :type="type" :shipmentModal="shipmentModal" @shipmentModal="shipmentModal = $event" @loadShipments="loadShipments"></shipment>
    </div>
    <div class="md:flex md:flex-wrap grid grid-cols-2 gap-2 items-center whitespace-nowrap md:mb-6 mb-3">
      <request-button v-if="user.status === 'active'" :selected="selected" @selected="selected = $event" @loadShipments="loadShipments"></request-button>
      <cancel-shipments-button v-if="user.status === 'active'" :selected="selected" @selected="selected = $event" @loadShipments="loadShipments"></cancel-shipments-button>
      <download-button v-if="$store.state.AppActiveUser.userRole === 'merchant'" :selected="selected"></download-button>
      <export-button class="m-0 whitespace-nowrap"></export-button>
    </div>
    <shipblu-table
      :sst="true"
      orders
      multiple
      v-model="selected"
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      pagination
      :data="returns"
      :tableLoader="tableLoader"
    >
      <template slot="header">
        <div class="lg:w-auto w-full md:mt-0 lg:flex grid grid-cols-2 gap-2 items-center">
          <table-filter icon="BarChart2Icon" filterLabel="Status" :filters="statuses" :statusFilters="statusOptions" @changeFilter="changeStatus" @filterValue="statusValue = $event"/>
          <table-filter icon="FlagIcon" filterLabel="Flag" :filters="flagFilters" :statusFilters="flagOptions" @changeFilter="changeFlag" @filterValue="flagValue = $event"/>
        </div>
      </template>
      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Created')}}</shipblu-th>
        <shipblu-th>{{$t('Received')}}</shipblu-th>
        <shipblu-th>{{$t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Refund Amount')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th class="step-12 order-1 flex justify-center" v-if="(!$route.params.merchantID && !$route.params.warehouseID) || $store.state.AppActiveUser.userRole === 'customer-support'">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-returns`, params: { type: 'returns', orderID: data[indextr].id }}">

          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="text-left sm:order-3 order-13 sm:pointer-events-none">
            <div class="sm:static absolute top-3 transform-50" v-on:click.stop>
              <p class="flex gap-1 items-center sm:text-base text-lg">
                <span>{{ data[indextr].tracking_number }}</span>
                <span @click="copied(data[indextr].tracking_number)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
              <div class="flex gap-1 items-center sm:mt-2 mt-1 sm:justify-start justify-center">
                <div v-if="data[indextr].fulfillment_provider_metadata" class="flex gap-1 px-1.5 items-center border-solid border border-grey-dark rounded-4xl h-5">
                  <feather-icon icon="DatabaseIcon" svgClasses="w-3 h-3"/>
                  <p class="font-medium text-xs leading-none">{{$t('FL')}}</p>
                </div>
                <p class="text-sm sm:text-left text-center" style="color: #F68B26;">{{ data[indextr].on_hold ? 'On Hold' : ''}}</p>
              </div>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].created" class="order-4">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Created')}}</p>
            <p class="sm:text-base text-sm">{{ common.formatDate(data[indextr].created, $i18n.locale) }}</p>
            <p class="text-sm sm:text-secondary sm:mt-2 mt-1">{{ $i18n.locale === 'en' ? common.gettingDate(data[indextr].created).split(',')[0] : common.gettingDate(data[indextr].created).split('،')[0] }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].pickupDate" class="order-5">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Received')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].pickup_date?  new Date(data[indextr].pickup_date).toLocaleDateString('fr-CA') : 'N/A' }}</p>
            <p class="text-sm sm:text-secondary sm:mt-2 mt-1">{{ data[indextr].pickup_date ? ($i18n.locale === 'en' ? common.gettingDate(data[indextr].pickup_date).split(',')[0] : common.gettingDate(data[indextr].pickup_date).split('،')[0]) : '' }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerInfo" class="order-6 sm:pointer-events-none">
            <div v-on:click.stop>
              <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Customer')}}</p>
              <p class="sm:text-base text-sm">{{ data[indextr].customer.full_name }}</p>
              <p class="text-sm flex gap-1 sm:text-secondary sm:mt-2 mt-1">
                {{ data[indextr].customer.phone }}
                <span @click="copied(data[indextr].customer.phone)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].location" class="order-7">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Location')}}</p>
            <p class="sm:text-base text-sm">{{ $t(data[indextr].customer.address.zone.name) }}</p>
            <p class="text-sm sm:text-secondary sm:mt-2 mt-1">{{ $t(data[indextr].customer.address.zone.city.name) }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].packages" class="order-8">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Cash Amount')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].cash_amount && parseInt(data[indextr].cash_amount) > 0 ? ((Number(data[indextr].cash_amount)).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'N/A' }} </p>
            <p class="text-sm sm:text-secondary sm:mt-2 mt-1">{{ data[indextr].packages.length }} {{$t('Packages')}}</p>
          </shipblu-td> 

          <shipblu-td class="order-9" :data="data[indextr].status" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;" @click="removeAttribute($event), openNotificationFailureData(data[indextr])">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
            <p class="text-sm sm:text-secondary pl-2">{{ data[indextr].returned_date ? common.formatDate(data[indextr].returned_date, $i18n.locale) : '' }}</p>
          </shipblu-td>

          <shipblu-td class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right" @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item @click="viewReturn(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="EyeIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('View Return')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <!-- ALLOW CANCEL IF NOT DELIVERED OR RETURNED -->
                  <vs-dropdown-item
                    v-if="data[indextr].status === 'created'"
                    @click="updateShipmentStatus(data[indextr], 'cancel this order', 'cancelled')"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="XCircleIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Cancel Request')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    v-if="data[indextr].status === 'returned'"
                    @click="getInvoice(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="DownloadIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Download Invoice')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationObj" @changePageSize="changePageSize" @changePageNumber="changePageNumber($event)" />
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import Shipment from './components/Shipment.vue'
import i18nData from '../../i18n/i18nData.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ExportButton from '../headOfFleet/components/ExportButton.vue'
import CancelShipmentsButton from '../admin/components/CancelShipmentsButton.vue'
import RequestButton from './components/RequestButton.vue'
import TableFilter from './components/TableFilter.vue'
import ShipbluCursorPagination from '../../layouts/components/ShipbluCursorPagination.vue'
import DownloadButton from '../admin/components/DownloadButton.vue'

export default {
  props: ['warehouseID'],
  data () {
    return {
      common,
      user: {},
      userRole: this.$store.state.AppActiveUser.userRole,
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      shipmentModal: false,
      returns: [],
      type: '',
      searchVal: '',
      paginationObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      warehousesDic: {},
      merchantID: '',
      trackingData: {},
      trackingStatus: '',
      searchInProgress: false,
      searchedValue: ' ',
      fromDate: '',
      toDate: '',
      criteria: '',
      tableLoader: false,
      merchantStatus: '',
      statusValue: '',
      flagValue:'',
      flagOptions:[
        {
          name: 'On Hold',
          value: 'on_hold'
        }
      ],
      statusOptions: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'created',
          value: 'created'
        },
        {
          name: 'return requested',
          value: 'return_requested'
        },
        {
          name: 'out for pickup',
          value: 'out_for_pickup'
        },
        {
          name: 'return attempted',
          value: 'return_attempted'
        },
        {
          name: 'picked up',
          value: 'picked_up'
        },
        {
          name: 'in transit & en route',
          value: 'in_transit, en_route'
        },
        {
          name: 'out for return',
          value: 'out_for_return'
        },
        {
          name: 'Returned',
          value: 'returned'
        },
        {
          name: 'Cancelled',
          value: 'cancelled'
        }
      ],
      statuses: [],
      flagFilters:[]
    }
  },
  watch: {
    '$route.query' () {
      if (!this.$route.query.flag && !this.$route.query.status) {
        this.flagFilters = []
        this.statuses = []
        this.loadShipments()
      }
    }
  },
  methods: {
    async copied (data) {
      try {
        await navigator.clipboard.writeText(data)
      } catch ($e) {
        this.$vs.notify({
          color:'dark',
          title:i18nData[this.$i18n.locale]['cannot copy!'],
          position: 'bottom-center'
        })
      }
    },
    openNotificationFailureData (order) {
      common.openNotificationFailureData(this, order)
    },
    changePageNumber (val) {
      this.loadShipments(this.paginationObj[val])
      this.paginationObj.currentPage = val === 'next' ? this.paginationObj.currentPage + 1 : this.paginationObj.currentPage - 1
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.loadShipments()
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    loadShipments (cursorVal) {
      if (!cursorVal) this.paginationObj.currentPage = 1
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?current_warehouse=${this.$route.params.warehouseID ? this.$route.params.warehouseID : ''}&${this.flagFilters.includes('on_hold') ? 'on_hold=true' : `status=${this.statuses}`}&cursor=${cursorVal ? cursorVal : ''}&limit=${this.maximumItems}&search=${this.searchVal}&${this.criteria}_after=${this.fromDate ? this.fromDate : ''}&${this.criteria}_before=${this.toDate ? this.toDate : ''}`
      sendRequest(true, false, this, `api/v1/returns/${query}`, 'get', null, true,
        (response) => {
          this.returns = response.data.results
          this.paginationObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    handleSearchDate (start, end, criteria) {
      this.fromDate = start ? new Date(start).toISOString().split('T')[0] : ''
      this.toDate = end ? new Date(end).toISOString().split('T')[0] : ''
      this.criteria = criteria
      this.loadShipments()
    },
    handleSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadShipments()
    },
    changeStatus () {
      this.flagFilters = []
      this.statuses = this.statuses.includes(this.statusValue) ? this.statuses.filter(item => item !== this.statusValue) : [...this.statuses, this.statusValue]
      if (this.statusValue === 'all') this.statuses = []
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-returns-view`,
        query: {
          status: this.statuses
        }
      }).catch(() => {})
      this.loadShipments()
    },
    changeFlag () {
      this.statuses = []
      this.selected = []
      this.flagFilters = this.flagFilters.includes(this.flagValue) ? [] : [this.flagValue]
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-returns-view`,
        query: {
          flag: this.flagFilters
        }
      }).catch(() => {})
      this.loadShipments()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    updateShipmentStatus (data, sentence, status) {
      this.trackingData = data
      this.trackingStatus = status
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: `Are you sure you want to ${sentence} ?`,
        acceptText: 'Yes',
        accept: this.confirmUpdateShipmentStatus
      })
    },
    confirmUpdateShipmentStatus (data, status) {
      let trackings = []
      if (data) {
        trackings = [
          {
            order: data.id,
            packages: data.packages.map(item => item.package.id),
            status
          }
        ]
      } else {
        trackings = [
          {
            order: this.trackingData.id,
            packages: this.trackingData.packages.map(item => item.package.id),
            status: this.trackingStatus
          }
        ]
      }
      sendRequest(false, false, this, 'api/v1/tracking/', 'post', trackings, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Return'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.loadShipments()
        }
      )
    },
    viewReturn (data) {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-returns`,
        params: {
          type: 'returns',
          orderID: data.id
        }
      }).catch(() => {})
    },
    getInvoice (data) {
      sendRequest(false, false, this, `api/v1/billing/merchants/${data.merchant.id}/invoices/?invoiceorder__order=${data.id}`, 'get', null, true,
        (response) => {
          this.downloadInvoice(response.data.results[0].id)
        }
      )
    },
    downloadInvoice (invoiceId) {
      sendRequest(false, false, this, `api/v1/billing/invoices/${invoiceId}/pdf/`, 'get', null, true,
        (response) => {
          const file_URl = response.data.file_url
          window.open(file_URl, '_blank')
        }
      )
    }
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  components: {
    Shipment,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ExportButton,
    CancelShipmentsButton,
    RequestButton,
    TableFilter,
    ShipbluCursorPagination,
    DownloadButton
  },
  created () {
    this.flagFilters = this.$route.query.flag ? typeof (this.$route.query.flag) === 'string' ? Array(this.$route.query.flag) : this.$route.query.flag : []
    this.statuses = this.$route.query.status ? typeof (this.$route.query.status) === 'string' ? Array(this.$route.query.status) : this.$route.query.status : []
    const merchantData = common.checkMerchantInLocalStorage(this)
    merchantData.then(results => {
      this.user = results.merchantData
    })
    this.loadShipments()
  }
}
</script>

<style lang="scss">
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
